<template>
    <div class="home">
        <div class="page-container d-none d-sm-block">
            <div class="inner-content d-flex justify-space-between align-center mx-auto">
                <div style="max-width:40%">
                    <div class="text-h5 font-weight-bold primary-text">{{ $t('AppBar.SubMenu.Comm') }}</div>
                    <div class="mt-6">{{ $t('Page.Comm.Desc') }}</div>
                    <v-btn depressed color="#AC8250" width="126" height="46" class="white--text text-body-1 mt-6">{{ $t('Page.Comm.AppBtn') }}</v-btn>
                </div>
                <div style="max-width:40%">
                    <v-img src="@/assets/images/community.png"></v-img>
                </div>
            </div>
        </div>
        <div class="d-block d-sm-none px-6 py-8" style="background:#FFF9F7">
            <div class="text-h5 font-weight-bold primary-text">{{ $t('AppBar.SubMenu.Comm') }}</div>
            <div class="mt-6">{{ $t('Page.Comm.Desc') }}</div>
            <v-btn depressed color="#AC8250" width="100%" height="46" class="white--text text-body-1 mt-6">{{ $t('Page.Comm.AppBtn') }}</v-btn>
            <v-img src="@/assets/images/community.png"></v-img>
        </div>
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Footer from '../../components/Footer.vue'
  
  
  export default {
    name: 'Home',
    components: {
      Footer
    },
    mounted(){
    }
  }
  </script>
  
  <style lang="scss">
  
    .page-container {
        min-height: calc(100vh - 289px);
        min-width: 768px;
        padding: 120px 120px 90px;
        background: #FFF9F7;

        .inner-content {
            max-width:1800px;
        }
    }
  
    @media screen and (max-width: 960px) {
      .mobile-container {
        overflow: hidden;
      }
    }
  </style>
  